@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
*{
  padding: 0;margin: 0;
  box-sizing: border-box;
}


body{
  font-family: 'Raleway', sans-serif;
}
img{
  border-radius: 10px !important;
}
a{
  text-decoration: none !important;
}

 .vertical-timeline-element-date{
  color: #ff2d34 ;
  font-weight: 800  !important;
  font-size:x-large !important;
}
/* Vpes Path */


/* VPES PAth header */
.container-experiences{
/* background-image: url("https://res.cloudinary.com/ddy8u7ff2/image/upload/v1680695823/VishvasWebsite/jlvnlf7ynllfqetiqzq4.jpg"); */
  background-color: #121212;
}
.headerSpan{
  justify-content: center;
  display: flex;
}
.header-Experiences{
  display: inline-flex;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 30px;

}
@media(min-width : 1000px){
  .header-Experiences{
    margin-left: 100px;
  }
 
    
}
.header-Experiences h1{
  font-weight: 800;
  line-height: 64px;
  color: white;
  text-shadow: 2px 2px black;
  padding-bottom: 10px !important;
  border-bottom:2px solid #DC0000;
}
.header-Experiences .v{
  font-size: 74px;
  color:white;
}

.vertical-timeline-element{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/* Gurukul */

.gurukul{
  padding-top: 80px !important;
  background-color: #121212 !important;
}
@media(min-width:700px){
  .FacultyHeader{
    margin-left: 40px !important;
  }
}
@media(max-width:700px){
  .FacultyHeader{
    margin-left: 20px !important;
  }
}
/* ContactUs CSS */
.main-contactus{
  background-color: #fff;
}
.container-Contactus{
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  
  padding-bottom: 50px !important;
}
.HeaderContactUs {
  display: flex;
  justify-content: center;
}
.HeaderContactUs h2{

  font-weight: 500;
  color: #212121;
  
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  padding: 20px;
  padding-bottom: 5px;
}
.cards-Contactus{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.card-Holder{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
@media(max-width:800px){
  .card-Holder{
    flex-direction: column;
  }
  .cards-Contactus{
    width: 100% !important;
    margin-left: 10% !important ;
  }
  .vertical-timeline-element-date{
    color: #121212 ;
    font-weight: 800  !important;
    font-size:x-large !important;
  }
}
.card-contact1 h2{
  color: #DC0000;
}

.card-contact2 h2{
  color: #DC0000;
}
.card-contact1{

  margin-top: 50px;
  padding-top: 20px;
  border-radius: 5%;
  background-color: #171717;
  color: #fff;
  width: 300px;
 padding-left: 20px;
}

.card-contact2{
  border: 2px solid black;
  margin-top: 50px;
  padding-top: 20px;
  border-radius: 5%;
  background-color: #171717;
  color: #fff;
  width: 300px;
  padding-left: 20px;
}

.tagline-contact{
  font-weight: 700;
  color:#fff !important;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  /* background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%); */
  border-bottom: 2px solid #f6d365;

  font-size: 40px;
  margin-bottom: 30px;
  display: inline-block;
}
.contact {
  background-color: #121212;
  padding: 60px 0 200px 0;

}
@media(max-width:600px){
  .contact{
    height: 140vh !important;
  }
}
@media (max-width : 485px){
  .contact{
    height: 150vh !important;
  }
}
@media (max-width : 385px){
  .contact{
    height: 185vh !important;
  }
}
.contact img {
  width: 600px;
  height: 400px;
  object-fit: contain;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background:#FFF6C3;
  border: 1px solid #121212;
  border-radius: 20px;
  color: #121212;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #121212;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 10px;
  left: 0px !important;
  position: relative;
  transition: 0.3s ease-in-out;

}
@media(min-width:800px){
  .contact form button {
    left:268px
  }
}

.contact form button:active{
  color: #f6d365;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
  border-radius: 10px;
}
.contact form button::before {
  content: "";
  background: #541212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}
.contact form button:hover::before {
  width: 100%;
}
.ant-modal-footer .ant-btn-primary{
  background-color:blue;
}
.EPCMargin{
  margin-top: 25px !important;
  height: 25px;
}
.TractionDIV{
  margin-top: 25px !important;
  height: 25px;
}

@media(max-width:700px){
  .EPCMargin{
    margin-top: 55px !important;
    height: 55px;
  }
}
@media(max-width:700px){
  .TractionDIV{
    margin-top: 5px !important;
    height: 5px;
  }
 
}
@media(max-width:800px){
  .Map{
    width: 22rem !important;
  }
}
@media(max-width:430px){
  .ColReverse{
    display: flex;
    flex-direction: column-reverse;
  }
  .Map{
    margin-top: 20px;
  }
}
.Map{
  width: 600px;
  height: 400px;
}