.RDSOContainers{
    display: flex;
    flex-direction: column;
}
.SectionRDSO{
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 70vh;
}
.ModalsRDSO{
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
}
.RDSOContainers img{
    height: 300px;
    width: 500px;
    object-fit: fill;
}
@media(max-width:700px){
    .SectionRDSO{
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 45vh !important;
    }
    .RDSOContainers img{
        height: 200px !important;
        width: 200px;
        object-fit: cover ;
    }
    .ModalButton{
        margin-top: 20px;
        height: 210px !important;
    }
}
.ModalButton{
    height: 310px;
}
.RDSO span{
    display: flex;
}
.RDSO .v{
    font-size: 54px;
}
.RDSO h2{
    margin-left: 0 !important;
    line-height: 74px;
    border-bottom: 2px solid #DC0000;
}
.RDSO h2::first-letter{
    font-size:40px ;
    
}