
  .container-sliderAUTO {
    max-width: 100vw;
    height: 100vh;
    margin: 10px auto 0;
    background-color: #121212;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media(min-width:900px){

  .container-sliderAUTO {
    max-width: 100vw;
    height: 100vh;
    margin: 10px auto 0;
    background-color: #121212;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  }
  @media(max-width:900px){
    .container-sliderAUTO {
      
      height:60vh ;
      
    }

  }
  .container-sliderAUTO h2{
    border-bottom: 2px solid #DC0000;
  }
  .container-sliderAUTO .SlideShowKHeader h2{
    color: #fff;
    font-weight: 700;
    padding-bottom: 10px;
  }
  .SlideShowKHeader{
    display: inline-flex;
    margin: 20px;
  }
.paraSlideShowK{
  margin-left: 30px;
  text-transform: none;
}
  @media screen and (max-width: 700px){
    .container-slider {
      margin: 0px 10px 0;
    }
  }
  .slideAUTO {
    width: 100%;
    height: 90vh !important;
    position: absolute;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
 .slideAUTO img{
  object-fit: fill !important;
  width: 70vw;
  position:absolute;
  top: 30px;
  height: 90vh !important;
 }
  .slideAUTO .content{
    z-index: 10;
    position: absolute;
    top: 50vh;
    color: white;
   left: 100px;
   
  }

  .slideAUTO .content .headerSlideShowA {
  font-size: 36px !important  ;
  line-height: 74px !important;
  border-bottom: #DC0000 2px solid;
  color: #fff;
  text-shadow: 2px 2px #121212;
  text-align: left;
  font-weight: 600 !important;
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 70px;
    height: 70px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    filter: invert(16%) sepia(36%) saturate(5355%) hue-rotate(347deg) brightness(82%) contrast(123%);
    width: 70px;
    height: 70px;
    pointer-events: none;
  }

  
  
  .container-dots {
   
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
   background-color: whitesmoke;
    margin: 0 5px;
    
  }
  .dot.active {
    background: #DC0000;
  }
  

  .headerSlideShowTraction{
    position: relative;
    font-size: 36px   ;
    line-height: 44px ;
    border-bottom: #DC0000 2px solid;
    color: #fff;
    bottom: 333px;
    
   
    left: 150px;
    text-shadow: 2px 2px #121212;
    text-align: left;
    font-weight: 600 ; 
  }
  .headerSlideShowProcess{
    position: relative;
    font-size: 36px   ;
    line-height: 44px ;
    border-bottom: #DC0000 2px solid;
    color: #fff;
    bottom: 333px;
    
   
    left: 150px;
    text-shadow: 2px 2px #121212;
    text-align: left;
    font-weight: 600 ; 
  }

@media(min-width:800px){
 
  .statsRR{
    position: relative;
    top: 80px;
    display: flex;
    left: 76.22vw;
    flex-direction: column;
  }
  .paraRR p{
    margin-left: 50px;
   width: 90%;
  }
  .statRR1{
    height: 150px;
    width: 200px;
    margin-top: 10px;
    background-color: #FFDB89;
    display: flex;
    flex-direction: column;
  }
  .statRR2{
    height: 150px;
    width: 200px;
    margin-top: 10px;
    background-color: #FFDB89;
    display: flex;
    flex-direction: column;
  }
  .statRR2 h4{
    font-weight: 700;
    padding-left: 30%;
    padding-top: 15%;
    font-size: xx-large;
  
  }
  .statRR1 h4{
    font-weight: 700;
    padding-left: 30%;
    padding-top: 10%;
    font-size: xx-large;
  
  }

.statRR2 p {
  font-size: larger;
  padding-top: 5%;
  font-weight: 700;
  padding-left: 8%;
}

.statRR1 p {
  font-size: larger;
  
  font-weight: 700;
  padding-left: 10%;
}
.ButtonRR.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.ButtonRR.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}
}
@media(max-width:800px){
  .statsRR{
    position: relative;
    display: flex;
    flex-direction: row;
   margin: 10px;
  
  }
  .paraRR p{
    margin-left: 20px !important;
   width: 90%;
  }
  .statRR1{
    height: 100px;
    width: 200px;
   
    background-color: #FFDB89;
    display: flex;
    flex-direction: column;
  }
  .statRR2{
    height: 100px;
    width: 200px;
    border-left: 1px solid black !important;
    background-color: #FFDB89;
    display: flex;
    flex-direction: column;
  }
  .statRR2 h4{
    font-weight: 700;
    padding-left: 35%;
    padding-top: 10%;
    font-size: large;
  
  }
  .statRR1 h4{
    font-weight: 700;
    padding-left: 35%;
    padding-top: 10%;
    font-size: large;
  
  }

.statRR2 p {
  font-size:medium;
  padding-top: 5%;
  font-weight: 700;
  padding-left: 15%;
}

.statRR1 p {
  font-size: medium;
  font-weight: 700;
  padding-left: 15%;
}
.ButtonRR  {
display: none ;

}
.slideAUTO img{
  object-fit: fill;
  top: 30px;
  width: 90vw !important;
  height: 40vh !important;
  position: relative;
  
 }
}

@media(max-width:500px){
  .headerSlideShowTraction{
    position:relative;
    font-size: 16px   ;
    line-height: 16px ;
    border-bottom: #DC0000 2px solid;
    color: #fff;
    bottom: 130px;
    
   right: 100px !important;
    left: 10px;
    text-shadow: 2px 2px #121212;
    
    font-weight: 600 ; 
  }
  .headerSlideShowProcess{
    position:relative;
    font-size: 16px   ;
    line-height: 16px ;
    border-bottom: #DC0000 2px solid;
    color: #fff;
    bottom: 100px;
    
   right: 100px !important;
    left: 10px;
    text-shadow: 2px 2px #121212;
    
    font-weight: 600 ; 
  }
  .content{
    left: 10px !important;
  }
  .container-sliderAUTO{
    height: 70vh !important;
  }
  .slideAUTO img{
    object-fit: fill !important;
   
    height: 70vh !important;
   }
}
@media(max-width:380px){
  .headerSlideShowTraction{
    position:relative;
    font-size: 16px   ;
    line-height: 16px ;
    border-bottom: #DC0000 2px solid;
    color: #fff;
    bottom: 170px;
    
   right: 100px !important;
    left: 10px;
    text-shadow: 2px 2px #121212;
    
    font-weight: 600 ; 
  }
  .headerSlideShowProcess{
    
    bottom: 80px;
    
    
  }
  .content{
    left: 10px !important;
  }
  .container-sliderAUTO{
    height: 80vh !important;
  }
  .slideAUTO img{
    object-fit: fill !important;
   
    height: 80vh !important;
   }
}