.SectionEPC{
    margin-top: 50px;
    display: flex;
   justify-content: space-around;

}
.ContainerEPC{
    display: flex;
    flex-direction: row;
    width: 90vw;
    height: 100vh;
    justify-content: space-between;
}

.contentEPC{
    height: 100vh;
    width: 70vw;
}
.HeaderEPC span{
    display: flex;
   
}
@media(min-width:900px){
    .HeaderEPC{
        display: flex;
    }
    .HeaderEPC span{
        display: flex;
    }

}
.HeaderEPC h1{
    line-height: 10vh;
    
}
.v{
    font-size: 9vh;
    color: #DC0000;
}
.blocksSection{
    display: flex;
    justify-content: space-around;
}
@media(max-width:900px){
    .SectionEPC{
        margin-top: 0px;
    }
    .ContainerEPC{
        display: flex;
        flex-direction: column;
        height: 200vh !important;
    }
    .v{
        font-size: 34px !important;
    }
    .HeaderEPC h1{
        line-height: 10vh;
        font-size: 20px;
        border-bottom: 2px solid red;

    }
    .HeaderEPC{
        display: flex;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .contentEPC li {
        font-size: 17px;
    }
    .blocksEPC{
        padding-top: 0px;
        margin-right: 150px;
    }
}
.blocksEPC{
    padding-top: 100px;
    height: 100vh;
    width: 15vw;
    
    display: flex;
    flex-direction: column;

}
.block{
    height: 150px;
    width: 200px;
    border-radius: 10px ;
    background-color: #FFDB89;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.block h3{
    line-height: 74px;
    font-size: 44px;
    margin-left: 75px;
    color: #121212;
}

.block p{
    font-size: large;
    font-weight: 500;
    margin-left: 55px;
}
@media(max-width:600px){
    .blocksEPC{ 
       height: 500px !important;
        width: 12vw;
        padding-top: 20px;;
        display: flex;
        flex-direction: column;
    
    }
    .ContainerEPC{
        display: flex;
        flex-direction: column;
        height: 150vh !important;
        justify-content: space-around !important;
    }
    
    .block{
        height: 100px;
        width: 200px;
        border-radius: 10px ;
        background-color: #FFDB89;
        margin: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .block h3{
        line-height: 54px;
        font-size: 34px;
        margin-left: 85px;
        color: #121212;
    }
    
    .block p{

        font-size: medium;
        font-weight: 500;
        margin-left: 65px;
    }
}


