section{
    margin-top: 0px;
    height: 90vh;
}

.header-div{
    display: flex;
    flex-direction: column;
}
.Slider-DivRR{
    padding-top: 4%;
    margin-left: 15%;
}
@media(max-width:600px){
    .Slider-DivRR{
       
        margin-left: 7%;
    }
    .ButtonCOREBiggieRR{
        display: flex;
        justify-content: space-between;
        position: relative;
        bottom: 25vh !important ;
        z-index: 200;
        margin-left: 30px;
    }
    
    .ButtonCOREBiggieRR button img{
        height: 30px !important;
        width: 30px !important;
        filter: invert(12%) sepia(89%) saturate(7479%) hue-rotate(3deg) brightness(83%) contrast(114%);
    }
}

.header-divRaw{
    display: flex;
    flex-direction: column;
}
.Slider-DivPlant{
    display: flex;
    justify-content: space-between !important;
    height: auto;

    
}
@media (max-width:800px){
    .Slider-DivPlant{
        flex-direction: column !important;
    }
    .Card-Slider{
        height: 275px;
        width: 375px !important;
    }
}
@media (max-width : 1000px){
    .Slider-DivRR{
        flex-direction: column !important;
    }
}
.Card-Slider{
    height: 300px;
    width: 300px;
}
.Card-ContainerPlant{
    height: 400px !important;
    width: 400px;
    margin-top: 35px;
   
}
@media(min-width:900px){
    .Card-ContainerPlant{
       
       margin-right: 200px;
    }
}
.Card-ContainerVendors{
    height: 400px !important;
    width: 400px;
    margin-top: 35px;
   
}
@media(min-width:900px){
    .Card-ContainerVendors{
       
       margin-right: 100px;
    }
}
.button-slider{
   padding-left: 120px;
}
.button-sliderRaw{
    padding-left: 430px;
    margin-top: 20px;
 }
.readMore{
    margin-left: 150px;
}
.Content-containerPlant{
    margin-top: 80px;
}

@media(min-width : 900px){
    .Content-containerProcessPage{
        margin-right: 300px !important;
    }
}
.Card-ContainerProcessPage{
    height: 400px !important;
    width: 400px;
    
  
}


@media(min-width : 900px){
    .Content-containerProcessPageDryout{
        margin-top: 100px;
        margin-right: 300px !important;
    }
    .Card-ContainerRR{
        height: 80vh !important;
        width: 70vw !important;
        
    }
    .Card-ContainerRR .Card-Slider img{
        height: 60vh;
        width: 70vw;
        object-fit: fill !important;
    }
    .Card-ContainerRR .Card-Slider {
        height: 60vh !important;
    }

}
.Card-ContainerProcessPageDryout{
    height: 400px !important;
    width: 400px;
    
  
}
.ButtonCOREBiggieRR{
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 35vh;
    width: 70vw;
    z-index: 200;
}
.ButtonCOREBiggieRR img{
    height: 70px !important;
    width: 70px !important;
    filter: invert(12%) sepia(89%) saturate(7479%) hue-rotate(3deg) brightness(83%) contrast(114%);
}