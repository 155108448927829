.facutlyDiv{
    margin-top: 50px !important;

}
@media(max-width:600px){
   .FacultyContainer{
   height: 260px !important;
   }
    
}
@media(min-width:800px){
   
    .facutlyDiv p{
     color: #121212 !important;
     font-size: 20px;
 }
 .facutlyDiv li{
     color: #121212 !important;
     font-size: 20px;
 
 }
     
 }
.header-div{
    display: flex;
    flex-direction: column;
}
.facultyHeader{
    margin-left: 50px !important;
}
.facutlyDiv p{
    color: #121212 !important;
    
}
.facutlyDiv li{
    color: #121212 !important;

}
.Slider-Div{
    display: flex;
    justify-content: space-around !important;
    
}
@media (max-width:800px){
    .Slider-Div{
        flex-direction: column !important;
    }
    .Card-SliderFaculty{
        height: 275px;
        width: 375px !important;
       
    }
}
@media (max-width : 1000px){
    .Slider-Div{
        flex-direction: column !important;
    }
}
.Card-SliderFaculty{
    height: 300px;
    width: 300px ;
    position: relative;
}
.Card-SliderFaculty img{
    margin-left: 100px;
    height: 200px!important;
    width: 200px !important;
    object-fit: contain;
    border-radius: 50%;
}
.Card-SliderFacultyBG img{
    margin-left: 100px;
    height: 200px!important;
    width: 200px !important;
    object-fit: contain;
    border-radius: 50%;
}
.Card-SliderFaculty h4{
    position: absolute ;
    bottom: 50px;
    margin-left: 120px; 
}
.Card-SliderFacultyBG h4{
    position: absolute ;
    bottom: 50px;
    margin-left: 70px; 
}

.Card-SliderFaculty p{
    position: absolute ;
    bottom: 20px;
    margin-left: 145px; 
}
.Card-SliderFacultyBG p{
    position: absolute ;
    bottom: 20px;
    margin-left: 145px; 
}
@media(min-width:700px){
    .FacultyContainer{
        height: 400px !important;
        width: 400px !important;
        margin-top: 50px;
       margin-right: 150px;
    }
}
.ButtonFacutly{
    display: flex;
    justify-content: space-between;
    position: relative;
    right: 1vw;
    bottom: 42vh;
    width: 30vw;
    z-index: 200;
}
@media(max-width:600px){
    .ButtonFacutly{
        display: flex;
        justify-content: space-between;
        position: relative;
        left: 1vw;
        bottom: 20vh;
        width: 400px !important;
        z-index: 200;
    }
}
.ButtonFacutly img{
    height: 70px;
    width: 70px;
    filter: invert(12%) sepia(89%) saturate(7479%) hue-rotate(3deg) brightness(83%) contrast(114%);
}
.readMore{
    margin-left: 150px;
}
.slick-dots button{
   background-color: black !important;
}
