.sectionPadding{
    padding: 4rem 4rem ;
}
.Footer{
    background-color: #212121;
    border-top: 1px solid beige;
}
.sbFooter{
    display: flex;
    flex-direction: column;
}
.LogoFooter{
    display: inline-flex;
    text-align: center;
    position: relative;
    top: 40px;
    left: 75px;
}
.LogoFooter h4{
    position: relative;
    left: 10px;
    font-weight: 700;
    top: 15px;
    color: #fff;
}
@media(max-width:600px){
    .LogoFooter{
        display: inline-flexbox;
        text-align: center;
        position: relative;
        top: 30px;
       left: 0px;
       width: 80%;
       left: 30px;
    }
    .LogoFooter h4{
        position: relative;
        left: 10px !important;
        font-weight: 700;
        top: 0px;
        color: #fff;
    }
}
.LogoFooter img{
    height: 50px;
    width: 50px;
}
.sbFooterLinks{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}
.sbFooterLinksDiv{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}
.sbFooterLinksDiv a{
    color: rgb(175,175,179);
    text-decoration: none;
}
.SocialMedia{
    display: flex;
    flex-direction: row;
}
.SocialMedia .social-icon {
   margin-right: 7px;
}
.sbFooterLinksDiv h4{
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
   
}
.sbFooterLinksDiv p{
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0;
    cursor: pointer;
}
.sbFooterBelow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}
.sbFooterBelowLinks{
    display: flex;
    flex-direction: row;
}

@media(min-width:600px){
    .sbFooterBelowLinks p{
        font-size: 20px;
        line-height: 15px;
        margin-left: 2rem;
        color: #fff;
        font-weight: 600;
    }
    .sbFooterBelowLinks span{
        color: lightseagreen;
        padding-bottom: 5px;
        border-bottom: 2px solid #fff;
    }
}
@media(max-width:600px){
    .sbFooterBelowLinks p{
        font-size: 18px;
        line-height: 15px;
        
        color: #fff;
        font-weight: 600;
    }
    .sbFooterBelowLinks span{
        color: lightseagreen;
        padding-bottom: 5px;
        border-bottom: 2px solid #fff;
    }
    .sbFooterBelow{
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
    }
}
hr{
    color: white !important;
    width: 100%;
}
.sbFooterCopyright p{
    font-size: 13px;
    line-height: 15px;
    color: rgb(255,255,255);
    font-weight: 600;
} 

@media screen and(max-width:850px){
    .sbFooterHeading h1{
        font-size: 44px;
        line-height: 50px;
       
    }
}

@media(max-width:600px){
    .sectionPadding{
        padding: 1rem 0rem !important;
        padding-top: 3rem  !important;
        padding-left: 0.5rem !important;

    }
}
@media screen and(max-width:550px){
    .sbFooterHeading h1{
        font-size: 34px;
        line-height: 42px;

    }
   
    .sbFooterLinks div{
        margin: 1rem 0;
    }
    .sbFooterBTn p {
        font-size: 14px;
        line-height: 20px;
    }
    .sbFooterBelow{
        flex-direction: column;
        justify-content: left;

    }
    .sbFooterBelowLinks{
        flex-direction: column;

    }
    .sbFooterBelowLinks p {
        margin-left: 0rem;
        margin-top: 1rem;
    }

}

@media screen and(max-width:400px){
    .sbFooterHeading h1{
        font-size: 27px;
        line-height: 30px;

    }
}