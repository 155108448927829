
.Gurantee{
    background-color:#fff;
    width: 90% !important; 
    margin-bottom: 50px;
}
.Gurantee .header-services{
    margin-bottom: 0px;
    display: flex;
    margin-left: 60px;
}
.Gurantee .header-services h2{
    color: #121212;
    font-weight: 800;
   
}
.Gurantee .points{
    margin-left: 70px;
    color: black;
    font-weight: 500;
}
.container-servicesPage{
    display: flex;
    flex-direction: column;
    width: 90%;
   
    height: 90vh !important;
    /* background: rgb(169,32,51);
background: linear-gradient(90deg, rgba(169,32,51,1) 0%, rgba(172,36,55,1) 9%, rgba(222,114,126,1) 63%, rgba(255,255,255,1) 100%); */
}
@media(min-width:900px){
    .container-servicesPage{
        margin-left: 70px !important;
    }
    .Gurantee{
        height: 45vh !important;
        margin-left: 0px !important;
    }
}
.card-services{
    margin-top: 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 32% !important;
}
.header-services{
    padding-top: 40px !important;
    padding-left: 30px;
}
.para-services{
    padding-left: 30px;
}
@media(max-width:912px){
    .container-servicesPage{
        width: 100% !important;
        border-radius: 1%;
        height: 75vh !important;
    }
    .prod-card{
        width: 260px;
        
    }
}
@media (max-width : 800px) {
    .card-services{
        flex-direction: column !important;
        width: 100% !important;
    }
    .container-servicesPage{
        margin-left: 20px !important;
        height: 210vh !important;
        width: 90% !important;
        border-radius: 1% !important;

    }
    .prod-card:nth-child(2){
        padding-top: 0px !important;
    }
}
@media (max-width : 450px){
    .container-servicesPage{
        height: 165vh !important;
    }
    .prod-card{
        width: 350px !important;
    }
}

@media (max-width : 385px){
    .container-servicesPage{
        height: 210vh !important;
    }
    .prod-card{
        width: 320px;
    }
}
@media (max-width : 385px){
    .prod-card{
        width: 250px;
    }
    .container-servicesPage{
        height: 240vh !important;
    }
}